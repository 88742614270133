<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <ValidationObserver v-slot="{ handleSubmit }">
        <!--begin::Form Wizard-->
        <form class="form" @submit.prevent="handleSubmit(createTransaction)">
          <!--begin::Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{
                    $t("branch_dash.customer_transaction")
                  }}</label>
                  <div class="col-lg-9 col-xl-9">
                    <multiselect
                      v-model="selectedCustomerTransaction"
                      :options="cryptoCurrencies"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeCustomerTransaction"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="message"
                      label="message"
                      :placeholder="$t('miracle_deal.choosen_currency')"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        $t(`branch_dash.wants_to_${option.type}`, {
                          code: option.code,
                        })
                      }}</template>
                      <template slot="option" slot-scope="{ option }">{{
                        $t(`branch_dash.wants_to_${option.type}`, {
                          code: option.code,
                        })
                      }}</template>
                      <template slot="noOptions">{{
                        "List is empty"
                      }}</template>
                    </multiselect>
                  </div>
                </div>

                <div v-show="selectedCustomerTransaction">
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.commission")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required"
                        :name="$t('commons.commission')"
                        v-slot="{ classes, errors }"
                      >
                        <div class="input-group" :class="classes">
                          <div class="input-group-prepend">
                            <span class="input-group-text">%</span>
                          </div>
                          <input
                            :class="classes"
                            type="number"
                            id="form-fee"
                            name="fee"
                            placeholder="0.00"
                            step="0.01"
                            :min="commissionMinAmount"
                            :max="commissionMaxAmount"
                            autocomplete="off"
                            class="form-control"
                            v-model="calculate.fee"
                            @input="onChangeCommission"
                          />
                        </div>
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.quantity")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        :rules="{required:!calculate.totalPrice}"
                        :name="$t('branch_dash.quantity')"
                        v-slot="{ classes, errors }"
                      >
                        <currency-input
                          class="form-control"
                          id="form-amount"
                          name="amount"
                          autocomplete="off"
                          :precision="amountPrecision"
                          :distractionFree="false"
                          v-model="calculate.amount"
                          placeholder="0.00000000"
                          @keyup="amountChanges"
                          @focus="amountIsFocused = true"
                          @blur="amountIsFocused = false"
                          :class="classes"
                        />
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.total_amount")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <div class="input-group">
                        <div class="col-12 col-lg-7 px-0">
                          <validation-provider
                            :rules="{isAmountValid:messageKey}"
                            :name="$t('branch_dash.total_amount')"
                            v-slot="{ classes, errors }"
                          >
                            <currency-input
                              class="form-control"
                              :class="classes"
                              id="form-totalPrice"
                              name="totalPrice"
                              autocomplete="off"
                              :precision="totalPricePrecision"
                              :distractionFree="false"
                              v-model="calculate.totalPrice"
                              @keyup="totalPriceChanges"
                            />
                            <span class="error__message">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                        <div class="col-12 col-lg-5 pr-0">
                          <validation-provider
                            rules="required"
                            :name="$t('miracle_deal.choosen_currency')"
                            v-slot="{ classes, errors }"
                          >
                            <multiselect
                              v-model="selectedCalculatableCurrency"
                              :options="calculatableCurrencies"
                              :internal-search="true"
                              :allow-empty="false"
                              open-direction="bottom"
                              @input="onChangeCalculatableCurrency"
                              deselect-label=""
                              select-label=""
                              selected-label=""
                              track-by="name"
                              label="name"
                              :placeholder="$t('miracle_deal.choosen_currency')"
                              :class="classes"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                                >{{ option.name }}</template
                              >
                              <template slot="option" slot-scope="{ option }">{{
                                option.name
                              }}</template>
                              <template slot="noOptions">{{
                                "List is empty"
                              }}</template>
                            </multiselect>
                            <span class="error__message">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="mt-3">
                        <label class="checkbox" ref="includeFeeLabel">
                          <input
                            type="checkbox"
                            name="includeFee"
                            :checked="true"
                            @change="totalPriceChanges"
                            ref="includeFee"
                            v-model="calculate.includeFee"
                          />
                          <span></span>
                          <div class="d-inline-block ml-2">
                            {{ $t("branch_dash.inc_commission") }}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="messageKey=='success'">
            <div class="separator separator-dashed my-10"></div>
            <div data-wizard-type="step-content">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.current_price")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span class="price-value">{{
                        calculated.price_formatted
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-xl-3 col-lg-3 col-form-label"
                      :class="{
                        'text-success': selectedTransactionType === 'buy',
                      }"
                      >{{ $t("branch_dash.quantity") }}</label
                    >
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span
                        class="price-value"
                        :class="{
                          'text-success': selectedTransactionType === 'buy',
                        }"
                      >
                        {{ calculated.amount_str }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.sub_total")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span class="price-value">{{
                        calculated.subTotal_formatted
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.commission_amount")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span class="price-value">{{
                        calculated.fee_formatted
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.total_volume")
                    }}</label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span class="price-value">{{
                        calculated.grandTotal_formatted
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="
                        col-xl-3 col-lg-3 col-form-label
                        text-success
                        font-weight-bolder font-size-h6
                      "
                      v-if="selectedTransactionType === 'sell'"
                    >
                      {{ $t("branch_dash.payable_to_customer") }}
                    </label>
                    <label
                      class="
                        col-xl-3 col-lg-3 col-form-label
                        text-danger
                        font-weight-bolder font-size-h6
                      "
                      v-if="selectedTransactionType === 'buy'"
                    >
                      {{ $t("branch_dash.customer_payment") }}
                    </label>
                    <div class="col-lg-9 col-xl-9 col-form-label">
                      <span
                        class="price-value font-weight-bolder font-size-h6"
                        :class="[
                          selectedTransactionType === 'sell'
                            ? 'text-success'
                            : 'text-danger',
                        ]"
                      >
                        {{ calculated.grandTotal_formatted }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row" v-if="selectedCustomerTransaction.code === 'USDT'">
                    <label class="col-xl-3 col-lg-3 col-form-label">Network</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required"
                        :name="$t('miracle_deal.choosen_currency')"
                        v-slot="{ classes, errors }"
                      >
                        <multiselect
                          v-model="selectedNetwork"
                          :options="networks"
                          :internal-search="true"
                          :allow-empty="false"
                          open-direction="bottom"
                          @input="onChangeSelectedNetwork"
                          deselect-label=""
                          select-label=""
                          selected-label=""
                          track-by="name"
                          label="name"
                          placeholder="Network"
                          :class="classes"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                          <template slot="option" slot-scope="{ option }">{{
                            option.name
                          }}</template>
                          <template slot="noOptions">{{
                            "List is empty"
                          }}</template>
                        </multiselect>
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.wallet_address")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required"
                        :name="$t('branch_dash.wallet_address')"
                        v-slot="{ classes, errors }"
                        v-if="selectedTransactionType === 'buy'"
                      >
                        <input
                          :class="classes"
                          type="text"
                          class="form-control"
                          v-model="calculated.wallet"
                        />
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                      <input
                        type="text"
                        v-else
                        class="form-control"
                        :value="calculated.wallet"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="messageKey=='success'">
            <div class="separator separator-dashed my-10"></div>

            <div class="pb-5" data-wizard-type="step-content">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group row">
                    <div class="col-lg-9 col-xl-6">
                      <h3 class="mb-10 font-weight-bold text-dark">
                        {{ $t("branch_dash.customer_information_title") }}
                      </h3>
                    </div>
                  </div>
                  <div
                    class="form-group row"
                    v-if="selectedTransactionType === 'sell'"
                  >
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.sender")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required"
                        :name="$t('commons.sender')"
                        v-slot="{ classes, errors }"
                      >
                        <multiselect
                          v-model="selectedWhoWillSent"
                          :options="whoWillSendSelectData"
                          :internal-search="true"
                          :allow-empty="false"
                          open-direction="bottom"
                          @input="onChangewhoWillSend"
                          deselect-label=""
                          select-label=""
                          selected-label=""
                          track-by="name"
                          label="name"
                          :placeholder="$t('commons.sender')"
                          :class="classes"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.name }}</template
                          >
                          <template slot="option" slot-scope="{ option }">{{
                            option.name
                          }}</template>
                          <template slot="noOptions">{{
                            "List is empty"
                          }}</template>
                        </multiselect>
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row" v-if="isMcmCustomer">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.transaction_branch_code")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <input
                        type="text"
                        id="form-transaction_branch_code"
                        name="transaction_branch_code"
                        class="form-control"
                        v-model="customer.transaction_branch_code"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("branch_dash.sales")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required"
                        :name="$t('branch_dash.sales')"
                        v-slot="{ classes, errors }"
                      >
                        <input
                          :class="classes"
                          type="text"
                          id="form-order_id"
                          name="order_id"
                          minlength="3"
                          value=""
                          autocomplete="off"
                          class="form-control"
                          v-model="customer.order_id"
                        />
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.name_surname")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required"
                        :name="$t('commons.name_surname')"
                        v-slot="{ classes, errors }"
                      >
                        <input
                          :class="classes"
                          type="text"
                          id="form-fullname"
                          name="fullname"
                          minlength="5"
                          value=""
                          autocomplete="off"
                          class="form-control"
                          v-model="customer.fullname"
                        />
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.mobile_phone")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required|numeric"
                        :name="$t('commons.mobile_phone')"
                        v-slot="{ classes, errors }"
                      >
                        <div class="input-group" :class="classes">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                              ><i class="la la-phone"></i
                            ></span>
                          </div>
                          <input
                            type="number"
                            :class="classes"
                            name="phone"
                            id="phoneValid"
                            minlength="10"
                            placeholder="Phone Number - 533xxxxxxx"
                            aria-describedby="basic-addon1"
                            class="form-control"
                            v-model="customer.phone"
                          />
                        </div>
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                      $t("commons.e_mail")
                    }}</label>
                    <div class="col-lg-9 col-xl-9">
                      <validation-provider
                        rules="required|email"
                        :name="$t('commons.e_mail')"
                        v-slot="{ classes, errors }"
                      >
                        <div class="input-group" :class="classes">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                              ><i class="la la-at"></i
                            ></span>
                          </div>
                          <input
                            type="email"
                            name="email"
                            :class="classes"
                            placeholder="Email"
                            aria-describedby="basic-addon1"
                            class="form-control"
                            v-model="customer.email"
                          />
                        </div>
                        <span class="error__message">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end border-top mt-5 pt-10">
              <button
                type="submit"
                class="
                  btn btn-success
                  font-weight-bolder
                  text-uppercase
                  px-9
                  py-4
                "
              >
                {{ $t("commons.submit") }}
              </button>
            </div>
          </div>

          <!--end::Actions-->
        </form>
        <!--end::Form Wizard-->
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {usdtNetworks} from '../../constants';

export default {
  name: "NewTransaction",
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedCustomerTransaction: null,
      selectedCalculatableCurrency: null,
      amountIsFocused: false,
      calculate: {
        currency_id: "",
        fee: 2,
        amount: 0,
        totalPrice: 0,
        total_currency_id: "try",
        includeFee: true,
      },
      customer: {
        who_will_send: 0,
        order_id: "",
        fullname: "",
        phone: "",
        email: "",
        transaction_branch_code: null
      },
      whoWillSendSelectData: [
        { value: "0", name: this.$t("branch_dash.not_a_mm_customer") },
        { value: "1", name: this.$t("branch_dash.mm_customer") },
      ],
      selectedWhoWillSent: null,
      calculateType: 'amount',
      networks: usdtNetworks,
      selectedNetwork: usdtNetworks[0]
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.transactions.isLoading,
      calculated: (state) => state.transactions.calculated,
      calcAction: (state) => state.transactions.calculated.action,
      cryptoCurrencies: (state) =>
        state.transactions.createTransactionData.cryptoCurrencies,
      calculatableCurrencies: (state) =>
        state.transactions.createTransactionData.calculatableCurrencies,
    }),
    messageKey() {
      return this.calculated?.message_key;
    },
    amountPrecision() {
      return this.selectedCustomerTransaction?.decimal || 8;
    },
    totalPricePrecision() {
      return this.selectedCalculatableCurrency?.decimal || 2;
    },
    selectedTransactionType() {
      return this.selectedCustomerTransaction?.type;
    },
    isMcmCustomer() {
      return `${this.customer.who_will_send}` === '1'
    },
    isBuy() {
      return this.selectedTransactionType === 'buy';
    },
    commissionMinAmount() {
      let min = 3; // if sell
      if(this.isBuy) {
        min = 2;
      }
      return min;
    },
    commissionMaxAmount() {
      return 5;
    }
  },
  methods: {
    ...mapActions("transactions", ["POST_CALCULATE"]),
    createTransaction() {
      const self = this;
      let params = {
        ...this.calculate,
        ...this.customer,
        wallet: this.calculated?.wallet || "",
        network: this.selectedNetwork.network
      };

      const transaction = {
        selectedCustomerTransaction: this.selectedCustomerTransaction,
        includeFee: this.calculate.includeFee ? "commons.yes" : "commons.no",
        price_formatted: this.calculated.price_formatted,
        grandTotal_formatted: this.calculated.grandTotal_formatted,
        amount_str: this.calculated.amount_str,
        subTotal_formatted: this.calculated.subTotal_formatted,
        fee_formatted: this.calculated.fee_formatted,
        selectedCalculatableCurrency: this.selectedCalculatableCurrency,
        selectedTransactionType: this.selectedTransactionType,
        selectedWhoWillSent: this.selectedWhoWillSent,
        customer: this.customer,
      };

      if(this.calculateType === 'amount') {
        params.totalPrice = null;
      } else {
        params.amount = this.calculated.amount;
      }

      this.$store
        .dispatch("transactions/CREATE_TRANSACTION", params)
        .then(function () {
          self.$store
            .dispatch("transactions/SET_TRANSACTION_SUMMARY", transaction)
            .then(() => {
              self.$router.push("/branch-dashboard/summary");
            });
        })
        .catch(() => {});
    },
    calculateTransaction() {
      let params = this.calculate;
      if(this.calculateType === 'amount') {
        // eslint-disable-next-line no-unused-vars
        const { totalPrice, ...rest } = this.calculate;
        params = rest;
      } else {
        // eslint-disable-next-line no-unused-vars
        const { amount, ...rest } = this.calculate;
        params = rest;
      }
      this.POST_CALCULATE(params).then(() => {
        // change network and set wallet address according to transaction type and wallet_multi prop
        this.selectedNetwork = usdtNetworks[0];
        if(this.selectedCustomerTransaction.code === 'USDT' && this.selectedNetwork && this.selectedTransactionType === 'sell') {
          const findErcWallet = this.calculated?.wallet_multi?.find(wallet => wallet.usdt_asset_id === 'USDT_ERC20');
          if(findErcWallet?.address) {
            this.$store.commit('transactions/SET_CALCULATED', {...this.calculated, wallet: findErcWallet.address});
          }
        }
        if(this.calculateType === 'amount') {
          // this.calculate.totalPrice = this.calculated.subTotal;
          this.calculate.totalPrice = null;
          // this.amountIsFocused && (this.calculate.totalPrice = null);
        } else {
          // this.calculate.amount = this.calculated.amount;
          this.calculate.amount = null;
        }
      })
      .catch(() => {})
    },
    onChangeCustomerTransaction() {
      this.calculate.currency_id = this.selectedCustomerTransaction.action;
      // disable selected currency in calculatableCurrencies
      this.$store.commit(
        "transactions/FILTER_CALCULATABLE_CURRENCIES",
        this.selectedCustomerTransaction.code
      );
      if (this.calculated) {
        this.calculate.amount = null;
        this.calculate.totalPrice = null;
        this.$store.commit("transactions/SET_CALCULATED", null);
      }
      this.calculate = {...this.calculate, fee: this.commissionMinAmount};
    },
    onChangeCalculatableCurrency() {
      this.calculate.total_currency_id = this.selectedCalculatableCurrency.code;
      if (this.calculate.amount) {
        this.calculate.totalPrice = null;
        this.calculateTransaction()
        return;
      }
      if (this.calculate.totalPrice) {
        this.calculateTransaction()
      }
    },
    amountChanges() {
      this.calculateType = "amount";
      this.$refs.includeFee.disabled = true;
      this.calculate.includeFee = false;
      this.$refs.includeFeeLabel.classList.value = "checkbox checkbox-disabled";
      this.calculate.totalPrice = null;
      if (
        this.calculate.amount &&
        this.calculate?.fee >= this.commissionMinAmount &&
        this.calculate?.fee <= this.commissionMaxAmount
      ) {
        this.calculateTransaction()
      }
    },
    totalPriceChanges() {
      this.calculateType = "totalPrice";
      this.$refs.includeFee.disabled = false;
      this.$refs.includeFeeLabel.classList.value = "checkbox";
      if (
        this.calculate.totalPrice &&
        this.calculate?.fee >= this.commissionMinAmount &&
        this.calculate?.fee <= this.commissionMaxAmount
      ) {
        this.calculateTransaction();
      }
    },
    onChangeCommission() {
      if (this.calculate?.fee >= this.commissionMinAmount && this.calculate?.fee <= this.commissionMaxAmount && this.calculate.amount) {
          this.calculateType = "amount";
          this.calculate.totalPrice = null;
          this.calculateTransaction();
      }
    },
    onChangewhoWillSend() {
      this.customer.who_will_send = this.selectedWhoWillSent.value;
    },
    onChangeSelectedNetwork() {
      if(this.selectedNetwork && this.selectedTransactionType === 'sell') {
        const findWalletBySelectedNetwork = this.calculated?.wallet_multi?.find(wallet => wallet.usdt_asset_id === this.selectedNetwork.usdt_asset_id);
        if(findWalletBySelectedNetwork?.address) {
          this.$store.commit('transactions/SET_CALCULATED', {...this.calculated, wallet: findWalletBySelectedNetwork.address});
        } else {
          this.$store.commit('transactions/SET_CALCULATED', {...this.calculated, wallet: null});
        }
      }
    }
  },
  created() {
    this.$store.commit("transactions/SET_CALCULATED", null);
    this.selectedWhoWillSent = this.whoWillSendSelectData[0];
    // moved this.$store.dispatch("transactions/GET_CREATE_TRANSACTION_DATA") to parent component, causing api request on every component re-render
    /* this.$store
      .dispatch("transactions/GET_CREATE_TRANSACTION_DATA")
      .then(() => {
        if (this.calculatableCurrencies.length > 0) {
          this.selectedCalculatableCurrency = this.calculatableCurrencies.find(
            (val) => val.code === "TRY"
          );
        }
      }); */
    if (this.calculatableCurrencies.length > 0) {
      this.selectedCalculatableCurrency = this.calculatableCurrencies.find(
        (val) => val.code === "TRY"
      );
    }
  },
};
</script>
<style>
.price-value {
  font-weight: bold;
}
</style>
