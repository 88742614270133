<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data'/>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('branch_dash.new_transaction') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button-->
              <router-link to="/branch-dashboard" class="btn btn-primary font-weight-bolder">
                <span class="svg-icon svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                       height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <circle fill="#000000" cx="9" cy="15" r="6"/>
                      <path
                          d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                          fill="#000000"
                          opacity="0.3"
                      />
                    </g>
                  </svg>
                  <!--end::Svg Icon--> </span
                >{{ $t('commons.back') }}
              </router-link>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <div class="row px-5 justify-content-center pb-5 mb-5">
              <button
                type="button"
                :class="{'border-bottom-primary text-primary': selectedTab === 'branchNewTransaction'}"
                class="btn btn-pill btn-borderless col-12 col-md-3"
                @click="changeSelectedTab('branchNewTransaction')"
              >
                {{ $t('branch_dash.new_transaction') }}
              </button>
              <div class="mx-1"></div>
              <button
                type="button"
                :class="{'border-bottom-primary text-primary': selectedTab === 'branchDepositNew'}"
                class="btn btn-pill btn-borderless col-12 col-md-3"
                @click="changeSelectedTab('branchDepositNew')"
              >
                {{ $t('deposit.name') }}
              </button>
            </div>
            <NewTransaction v-if="selectedTab === 'branchNewTransaction'" />
            <NewDepositTransaction v-if="selectedTab === 'branchDepositNew'" />
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import NewTransaction from "../../components/branchDashboard/NewTransaction";
import NewDepositTransaction from '../../components/branchDashboard/NewDepositTransaction.vue';

export default {
  name: 'branch-new-transaction',
  data() {
    return {
      selectedTab: 'branchNewTransaction',
    }
  },
  components: {
    NewTransaction,
    Breadcrumb,
    NewDepositTransaction
  },
  computed: {
    ...mapState({
      transactions: state => state.cryptobanks.transactions
    }),
    breadcrumb_data() {
      return {
        title:  this.$t('branch_dash.new_transaction'),
        subtitle: [
          { title: this.$t('branch_dash.title'), route: '/branch-dashboard' },
          this.$t('branch_dash.new_transaction')
        ]
      }        
    }
  },
  methods: {
    ...mapActions('cryptobanks', ['GET_TRANSACTIONS']),
    changeSelectedTab(tab) {
      this.selectedTab = tab;
    }
  },
  created() {
    this.$store.dispatch("transactions/GET_CREATE_TRANSACTION_DATA")

  }
}
</script>
