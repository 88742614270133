<template>
    <div class="row justify-content-center px-8 px-lg-10">
        <div class="col-xl-12 col-xxl-7 justify-content-center">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <!--begin::Form Wizard-->
                <form class="form" @submit.prevent="handleSubmit(handleSummary)">
                    <!--begin::Step 1-->
                    <div data-wizard-type="step-content" data-wizard-state="current" v-if="!isSummary">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                                        $t("branch_dash.sales")
                                    }}</label>
                                    <div class="col-lg-9 col-xl-9">
                                        <validation-provider rules="required" :name="$t('branch_dash.sales')"
                                            v-slot="{ classes, errors }">
                                            <input :class="classes" type="text" id="form-order_id" name="order_id"
                                                minlength="3" value="" autocomplete="off" class="form-control"
                                                v-model="formData.order_id" />
                                            <span class="error__message">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                                        $t("commons.name_surname")
                                    }}</label>
                                    <div class="col-lg-9 col-xl-9">
                                        <validation-provider rules="required" :name="$t('commons.name_surname')"
                                            v-slot="{ classes, errors }">
                                            <input :class="classes" type="text" id="form-fullname" name="fullname"
                                                minlength="5" value="" autocomplete="off" class="form-control"
                                                v-model="formData.full_name" />
                                            <span class="error__message">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                                        $t("commons.mobile_phone")
                                    }}</label>
                                    <div class="col-lg-9 col-xl-9">
                                        <validation-provider rules="required|numeric" :name="$t('commons.mobile_phone')"
                                            v-slot="{ classes, errors }">
                                            <div class="input-group" :class="classes">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="la la-phone"></i></span>
                                                </div>
                                                <input type="number" :class="classes" name="phone" id="phoneValid"
                                                    minlength="7" aria-describedby="basic-addon1" class="form-control"
                                                    v-model="formData.phone" />
                                            </div>
                                            <span class="error__message">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                                        $t("commons.e_mail")
                                    }}</label>
                                    <div class="col-lg-9 col-xl-9">
                                        <validation-provider rules="required|email" :name="$t('commons.e_mail')"
                                            v-slot="{ classes, errors }">
                                            <div class="input-group" :class="classes">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="la la-at"></i></span>
                                                </div>
                                                <input type="email" name="email" :class="classes" placeholder="Email"
                                                    aria-describedby="basic-addon1" class="form-control"
                                                    v-model="formData.email" />
                                            </div>
                                            <span class="error__message">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                                        $t("branch_dash.amount")
                                    }}</label>
                                    <div class="col-lg-9 col-xl-9">
                                        <div class="input-group">
                                            <div class="col-12 col-lg-7 px-0">
                                                <validation-provider :rules="{ required: true }"
                                                    :name="$t('branch_dash.amount')" v-slot="{ classes, errors }">
                                                    <currency-input style="min-height: 40px;" class="form-control"
                                                        id="form-amount" aria-describedby="form-amount-help" name="amount"
                                                        autocomplete="off" :precision="2" :distractionFree="false"
                                                        v-model="formData.amount" placeholder="0.00" :class="classes" />
                                                    <span class="error__message">{{ errors[0] }}</span>
                                                </validation-provider>
                                            </div>
                                            <div class="col-12 col-lg-5 pr-0">
                                                <validation-provider rules="required" :name="$t('commons.currency')"
                                                    v-slot="{ classes, errors }">
                                                    <multiselect v-model="selectedCurrency" :options="currencies"
                                                        :internal-search="true" :allow-empty="false" open-direction="bottom"
                                                        deselect-label="" select-label="" selected-label="" track-by="name"
                                                        label="name" :placeholder="$t('commons.currency')" :class="classes">
                                                        <template slot="singleLabel" slot-scope="{ option }">{{ option.name
                                                        }}</template>
                                                        <template slot="option" slot-scope="{ option }">{{
                                                            option.name
                                                        }}</template>
                                                        <template slot="noOptions">{{
                                                            "List is empty"
                                                        }}</template>
                                                    </multiselect>
                                                    <span class="error__message">{{ errors[0] }}</span>
                                                </validation-provider>
                                            </div>
                                        </div>
                                        <small id="form-amount-help" class="form-text text-muted">{{
                                            $t('finance.amount_to_pay') }}: <b class="text-dark-65">{{ getCalculatedAmount()
    }}</b></small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label">{{
                                        $t("branch_dash.fee_rate")
                                    }}</label>
                                    <div class="col-lg-9 col-xl-9">
                                        <validation-provider rules="required" :name="$t('branch_dash.fee_rate')"
                                            v-slot="{ classes, errors }">
                                            <div class="input-group" :class="classes">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                                <input :class="classes" type="number" id="form-fee" name="fee"
                                                    placeholder="0.00" step="0.01" min="0.00" autocomplete="off"
                                                    class="form-control" v-model="formData.fee_rate" />
                                            </div>
                                            <span class="error__message">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="d-flex justify-content-end border-top mt-5 pt-10">
                            <button type="submit" class="
                                                      btn btn-primary
                                                      font-weight-bolder
                                                      text-uppercase
                                                      px-9
                                                      py-4
                                                    ">
                                {{ $t("commons.continue") }}
                            </button>
                        </div>
                    </div>

                    <div class="d-flex flex-column" v-else>
                        <div class="d-flex flex-row justify-content-between mb-2 border-bottom">
                            <p>{{ $t('commons.name_surname') }}:</p>
                            <p class="font-weight-bold">{{ formData.full_name }}</p>
                        </div>
                        <div class="d-flex flex-row justify-content-between mb-2 border-bottom">
                            <p>{{ $t('commons.mobile_phone') }}:</p>
                            <p class="font-weight-bold">{{ formData.phone }}</p>
                        </div>
                        <div class="d-flex flex-row justify-content-between mb-2 border-bottom">
                            <p>{{ $t('commons.e_mail') }}:</p>
                            <p class="font-weight-bold">{{ formData.email }}</p>
                        </div>
                        <div class="d-flex flex-row justify-content-between mb-2 border-bottom">
                            <p>{{ $t('branch_dash.fee_rate') }}:</p>
                            <p class="font-weight-bold">{{ formData.fee_rate }}</p>
                        </div>
                        <div class="d-flex flex-row justify-content-between mb-2 border-bottom">
                            <p>{{ $t('branch_dash.amount') }}:</p>
                            <p class="font-weight-bold">{{ formData.amount }} {{ selectedCurrency.code }}</p>
                        </div>
                        <div class="d-flex flex-row justify-content-between mb-2">
                            <p>{{ $t('finance.amount_to_pay') }}:</p>
                            <p class="font-weight-bold">{{ getCalculatedAmount() }}</p>
                        </div>
                        <div class="d-flex justify-content-between mt-5 pt-10">
                            <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                                @click="isSummary = false">
                                {{ $t("commons.cancel") }}
                            </button>
                            <button @click="approve" type="button"
                                class="btn btn-success font-weight-bolder text-uppercase px-9 py-4">
                                {{ $t("commons.approve") }}
                            </button>
                        </div>

                    </div>
                    <!--end::Actions-->
                </form>
                <!--end::Form Wizard-->
            </ValidationObserver>
        </div>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
    name: "NewDepositTransaction",
    components: {
        Multiselect
    },
    data() {
        return {
            formData: {
                order_id: '',
                full_name: '',
                phone: '',
                email: '',
                amount: null,
                fee_rate: 0
            },
            selectedCurrency: null,
            isSummary: false

        };
    },
    computed: {
        ...mapState({
            currencies: (state) =>
                state.transactions.createTransactionData.calculatableCurrencies?.filter(val => !val.crypto),
        })
    },
    methods: {
        handleSummary() {
            this.isSummary = true;
        },
        approve() {
            this.formData = { ...this.formData, currency_id: this.selectedCurrency.id };
            this.$store.dispatch('transactions/CREATE_DEPOSIT_TRANSACTION', this.formData).then(() => {
                this.isSummary = false;
                this.formData = {
                    order_id: '',
                    full_name: '',
                    phone: '',
                    email: '',
                    amount: null,
                    fee_rate: 0
                };
                this.selectedCurrency = null;
                this.$refs.form.reset();
            }).catch(() => { })
        },
        getCalculatedAmount() {
            const { amount, fee_rate } = this.formData;
            let _amount = '0';
            if (amount && this.selectedCurrency) {
                const fee = ((parseFloat(amount) * parseFloat(fee_rate) || 0) / 100).toFixed(2);
                _amount = `${(parseFloat(amount) - fee).toFixed(2)} ${this.selectedCurrency.code}`
            }
            return _amount;
        }
    },
    created() {
    },
};
</script>
<style>
.price-value {
    font-weight: bold;
}
</style>
  